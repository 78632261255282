@font-face {
    font-family: 'Maison Neue Book';
    src: url('MaisonNeue-Book.woff2') format('woff2'),
        url('MaisonNeue-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('MaisonNeue-Light.woff2') format('woff2'),
        url('MaisonNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('MaisonNeue-Medium.woff2') format('woff2'),
        url('MaisonNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Portrait Text';
    src: url('PortraitText-Medium.woff2') format('woff2'),
        url('PortraitText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Portrait Text';
    src: url('PortraitText-Regular.woff2') format('woff2'),
        url('PortraitText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Portrait Text';
    src: url('PortraitText-Bold.woff2') format('woff2'),
        url('PortraitText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

